.Main {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  --border-bottom:none;
  .Submit {
    background: #267EF0;
    // opacity: 0.4;
    border-radius: 6px;
    color: #ffffff;
  }
  .Tip {
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: rgba(10, 17, 26, 0.5);
    padding: 8px 16px;
  }
  .FormLableText {
    display: flex;
    align-items: center;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 140%;
    color: #000000;
    .FormLableText_bt {
      color: rgb(255, 0, 0);
      height: 16px;
      font-size: 20px;
      font-weight: 300;
    }
  }
}
.Dialog{
  :global(.adm-center-popup-wrap) {
    width: 90vw;
    min-width: 90vw;
    max-width: 90vw;
  }
  :global(.adm-form) {
    width: 100%;

  }

}
.extraPart {
  border-left: solid 1px #eeeeee;
  padding-left: 12px;
  font-size: 17px;
  line-height: 22px;
}
.Code{
  //width: 80vw;
  :global(.adm-list-item-content-prefix) {
    width: 60px;
  }
    //.adm-form-item-label{
  //  width: 20px;
  //}
}