.Main {
  height: calc(100% - 24px * 2);
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // font-size: 24px;
  padding: 24px 20px;

  display: flex;
  flex-direction: column;
  .Title {
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: rgba(0, 0, 0, 0.9);
  }
  .Content {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.02em;

    color: rgba(0, 0, 0, 0.6);
  }
  .Buttons {
    display: flex;
    flex-direction: column;
    .HyButton {
      display: flex;
      border: 1px solid rgba(255, 255, 255, 0.16);
      border-radius: 6px;
      padding: 24px 20px;
      span {
        width: 100%;
      }
      .ButtonContent {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .ButtonContent_left {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .ButtonContent_info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
}
