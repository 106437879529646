.Main {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .Header {
    width: 100%;
    height: 160px;
    background: #3975C6;
    display: flex;
    flex-direction: column;
    //align-items: center;
    justify-content: flex-end;
    padding-left: 20px;
    .HeaderWelcome {
      font-weight: bold;
      font-size: 20px;
      color: #FFFFFF;
      margin-bottom: 30px;
      margin-top: 12px;
    }

  }

  .Content {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    background: #F5F6F7;
    border-radius: 12px 12px 0px 0px;
    margin-top: -12px;
    padding: 24px 16px;

    .Username {
      border: 1px solid rgba(8, 16, 26, 0.3);
      border-radius: 6px;
      height: 46px;
      box-sizing: border-box;
      padding: 0 12px;
      margin-bottom: 8px;
    }

    .Password {
      width: 100%;
      border: 1px solid rgba(8, 16, 26, 0.3);
      border-radius: 6px;
      height: 46px;
      box-sizing: border-box;
      display: flex;
      margin-bottom: 24px;
      .PasswordInput {
        height: 100%;
        padding: 0 12px;
      }
      .Eye {
        //line-height: 100%;
        width: 25px;
        display: flex;
        align-items: center;
      }
    }

    .Btn {
      height: 46px;
      width: 100%;
      background: #267EF0;
      border-radius: 6px;
      text-align: center;
      color: #FFFFFF;
      font-size: 17px;
    }

  }
}
