.Main {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;

}
.Dialog{
  :global(.adm-center-popup-wrap) {
    width: 90vw;
    min-width: 90vw;
    max-width: 90vw;
  }
  :global(.adm-form) {
    width: 100%;

  }

}
.extraPart {
  border-left: solid 1px #eeeeee;
  padding-left: 12px;
  font-size: 17px;
  line-height: 22px;
}
.Code{
  //width: 80vw;
  :global(.adm-list-item-content-prefix) {
    width: 60px;
  }
  //.adm-form-item-label{
  //  width: 20px;
  //}
}